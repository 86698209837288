import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import {
  NewTaskItemPayload,
  TaskItem,
  AddTaskFilesPayload,
  DuplicateTaskItem,
  AddItemOption,
  UpdateItemOption,
  CreateReport,
} from '@models/task-item.model';
import {
  ApiDataResponseObject,
  ApiResponse,
} from '@root/models/global-interfaces';
import { LucideIconData } from 'lucide-angular/icons/types';
import {
  Calendar,
  CalendarDays,
  ChevronsRight,
  Ellipsis,
  List,
  ListChecks,
  MoveRight,
  Paperclip,
  Repeat,
  Rows3,
  Rows4,
  SquareCheck,
  Tag,
} from 'lucide-angular';

export const enum ItemTypes {
  CheckBox = 1,
  RadioList = 2,
  TextBox = 3,
  DropdownBox = 4,
  DatePicker = 5,
  Label = 7,
  Reassign = 8,
  FileAttachment = 9,
  AssignTask = 10,
  DeadlineDatePicker = 11,
  AppendToTitle = 13,
  MultiSelect = 14,
  MultiSelectDropdown = 15,
}

export const itemIcons: Map<ItemTypes, LucideIconData> = new Map([
  [ItemTypes.CheckBox, SquareCheck],
  [ItemTypes.RadioList, List],
  [ItemTypes.TextBox, Ellipsis],
  [ItemTypes.DropdownBox, Rows3],
  [ItemTypes.DatePicker, CalendarDays],
  [ItemTypes.Label, Tag],
  [ItemTypes.Reassign, Repeat],
  [ItemTypes.FileAttachment, Paperclip],
  [ItemTypes.AssignTask, MoveRight],
  [ItemTypes.DeadlineDatePicker, Calendar],
  [ItemTypes.AppendToTitle, ChevronsRight],
  [ItemTypes.MultiSelect, ListChecks],
  [ItemTypes.MultiSelectDropdown, Rows4],
]);

export interface TaskItemOrderUpdate {
  taskItemID: number;
  order: number;
  parentItemOptionID: number;
  parentTaskItemID: number;
  itemID: number;
  multiSelectOptionsParentID: number;
}

export interface Assigntask {
  linkedTaskID: number;
}
export interface TaskItemOptionOrderUpdate {
  optionID: number;
  order: number;
}

export interface TaskItemOrderBulkUpdate {
  taskItems: TaskItemOrderUpdate[];
  taskItemOptions: TaskItemOptionOrderUpdate[];
}
export interface TaskItemPayload {
  itemTypeID?: number;
  parentItemOptionID?: number;
  multiSelectOptionsParentID?: number;
  prompt?: string;
  taskID?: number;
  task_id?: number;
  itemID?: number;
  item_id?: number;
  response?: string;
  optional?: boolean;
}

export interface TaskItemResponse {
  data: TaskItem
  message: string
  status: string
}

export interface SchedulePayload {
  default_time: number
  dueAt: string
  duration?: number
  failedTasksEnabled: boolean
  field1: number
  field2: number
  field3: string
  field4: string
  location_id?: number
  name: string
  new_tasks?: number
  reoccurAt: string
  startAt: string
  taskID?: number
  taskSetID?: number
  time_interval: string
  type: number
}
@Injectable()
export class TaskItemService {
  public apiUrl: string = environment.apiUrl;
  store: Record<string, unknown>;

  constructor(private http: HttpClient) {}

  addTaskItem(payload: NewTaskItemPayload): Observable<TaskItem> {
    return this.http.post(`${this.apiUrl}task-item`, payload)
      .pipe(
        map((res: ApiDataResponseObject<TaskItem>) => {
          return res.data;
        }),
        catchError(error => {
          return throwError(() => error);
        }),
      );
  }
  updateTaskItem(payload: TaskItemPayload): Observable<ApiResponse> {
    const { itemID, ...args } = payload;
    return this.http.put(`${this.apiUrl}task-item/${itemID}`, args).pipe(
      map((res: ApiResponse) => {
        return res;
      }),
      catchError(error => {
        return throwError(() => error);
      }),
    );
  }

  updateMultiSelectTaskItem(optionId: number, payload: TaskItemPayload): Observable<ApiResponse> {
    return this.http.put(`${this.apiUrl}multi-select-options/${optionId}`, payload).pipe(
      map((res: ApiResponse) => {
        return res;
      }),
      catchError(error => {
        return throwError(() => error);
      }),
    );
  }

  deleteTaskItem(taskItemID: number): Observable<TaskItem> {
    return this.http
        .delete(`${this.apiUrl}task-item/${taskItemID}`)
        .pipe(
          map((res: ApiDataResponseObject<TaskItem>) => {
            return res.data;
          }),
          catchError(error => {
            return throwError(() => error);
          }),
        )
  }

  bulkUpdateTaskItemOrder(taskItemUpdate: TaskItemOrderBulkUpdate): Observable<void> {
    return this.http.put(`${this.apiUrl}task-item/order`, taskItemUpdate).pipe(
      map(() => {
        return;
      }),
      catchError((error: string) => {
        return throwError(() => error);
      }),
    );
  }

  updateMultiSelectOrder(taskItemUpdate: {
    locationId: number;
    multiSelectIDs: {
      id: number;
      order: number;
    }[]
  }): Observable<void> {
    return this.http.put(`${this.apiUrl}update-multi-select-order`, taskItemUpdate).pipe(
      map(() => {
        return;
      }),
      catchError((error: string) => {
        return throwError(() => error);
      }),
    );
  }

  addSchedule(reqObj: SchedulePayload): Observable<SchedulePayload> {
    return this.http.post(`${this.apiUrl}schedules`, reqObj).pipe(
      map((res: ApiDataResponseObject<SchedulePayload>) => {
        return res.data;
      }),
      catchError(error => {
        return throwError(() => error);
      }),
    );
  }

  updateSchedule(reqObj: SchedulePayload, taskReoccurID: number): Observable<SchedulePayload> {
    return this.http.put(`${this.apiUrl}task-reoccur/${taskReoccurID}`, reqObj).pipe(
      map((res: ApiDataResponseObject<SchedulePayload>) => {
        return res.data;
      }),
      catchError(error => {
        return throwError(() => error);
      }),
    );
  }

  deleteSchedule(taskReoccurID: number): Observable<ApiResponse> {
    return this.http.delete(`${this.apiUrl}task-reoccur/${taskReoccurID}`).pipe(
      map((res: ApiResponse) => {
        return res.data;
      }),
      catchError(error => {
        return throwError(() => error);
      }),
    );
  }

  duplicateTaskItem(reqObj: DuplicateTaskItem): Observable<TaskItem> {
    return this.http
      .patch(
        `${this.apiUrl}task-item-duplicate/${reqObj.itemID}`,
        {
          reqObj,
        },
      )
      .pipe(
        map((res: ApiDataResponseObject<TaskItem>) => {
          return res.data;
        }),
        catchError(error => {
          return throwError(() => error);
        }),
      );
  }
  addOption(reqObj: AddItemOption): Observable<TaskItem> {
    return this.http
      .post(`${this.apiUrl}task-item-option`, reqObj)
      .pipe(
        map((res: ApiDataResponseObject<TaskItem>) => {
          return res.data;
        }),
        catchError(error => {
          return throwError(() => error);
        }),
      );
  }

  addMultiselectOption(reqObj: { task_id: number; item_id: number; prompt: string; }): Observable<TaskItem> {
    return this.http
      .post(`${this.apiUrl}multi-select-options`, reqObj)
      .pipe(
        map((res: ApiDataResponseObject<TaskItem>) => {
          return res.data;
        }),
        catchError(error => {
          return throwError(() => error);
        }),
      );
  }
  deleteTaskItemOption(optionID: number): Observable<TaskItem> {
    return this.http
      .delete(`${this.apiUrl}task-item-option/${optionID}`)
      .pipe(
        map((res: ApiDataResponseObject<TaskItem>) => {
          return res.data;
        }),
        catchError(error => {
          return throwError(() => error);
        }),
      );
  }

  deleteMultiselectTaskItemOption(optionID: number, taskID: number): Observable<TaskItem> {
    return this.http
      .delete(`${this.apiUrl}multi-select-options/${optionID}`, { body: { taskID } })
      .pipe(
        map((res: ApiDataResponseObject<TaskItem>) => {
          return res.data;
        }),
        catchError(error => {
          return throwError(() => error);
        }),
      );
  }

  updateOptionPrompt(reqObj: UpdateItemOption): Observable<TaskItem> {
    return (
      this.http
        .put(`${this.apiUrl}task-item-option/${reqObj.optionID}`, reqObj)
        .pipe(
          map((res: ApiDataResponseObject<TaskItem>) => {
            return res.data;
          }),
          catchError(error => {
            return throwError(() => error);
          }),
        )
    );
  }

  updateMultiselectOptionPrompt(reqObj: UpdateItemOption): Observable<TaskItem> {
    return (
      this.http
        .put(`${this.apiUrl}multi-select-options/${reqObj.optionID}`, reqObj)
        .pipe(
          map((res: ApiDataResponseObject<TaskItem>) => {
            return res.data;
          }),
          catchError(error => {
            return throwError(() => error);
          }),
        )
    );
  }

  createReport(reqObj: CreateReport): Observable<TaskItem> {
    return this.http
      .post(`${this.apiUrl}task-item-report`, reqObj)
      .pipe(
        map((res: ApiDataResponseObject<TaskItem>) => {
          return res.data;
        }),
        catchError(error => {
          return throwError(() => error);
        }),
      );
  }

  deleteTaskFile(fileID: number) {
    return this.http.delete(`${this.apiUrl}task-files/${fileID}`).pipe(
      map((res: ApiResponse) => {
        return res;
      }),
      catchError(error => {
        return throwError(() => error);
      }),
    );
  }

  getTaskFiles(itemID: number) {
    return this.http.get(`${this.apiUrl}task-files`, { params: { itemID } }).pipe(
      map((res: ApiResponse) => {
        return res;
      }),
      catchError(error => {
        return throwError(() => error);
      }),
    );
  }

  addTaskFiles(req: AddTaskFilesPayload) {
    return this.http.post(`${this.apiUrl}task-files`, req).pipe(
      map((res: ApiResponse) => {
        return res;
      }),
      catchError(error => {
        return throwError(() => error);
      }),
    );
  }

  assignTask(taskItemId: number, taskId: number) {
    const req: Assigntask = {
      linkedTaskID: taskId,
    };
    return this.http.put(`${this.apiUrl}task-item/assign-link/${taskItemId}`, req).pipe(
      map((res: Assigntask) => {
        return res;
      }),
      catchError(error => {
        return throwError(() => error);
      }),
    );
  }
}
