import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeToMeridian',
  standalone: true,
})
export class TimeToMeridianPipe implements PipeTransform {
  transform(timeString: string | undefined): string {
    if (!timeString) return '';

    /* eslint-disable @typescript-eslint/typedef */
    const splitArray = timeString.split(':').map(Number);
    /* eslint-disable @typescript-eslint/typedef */
    let hours = splitArray[0];
    const minutes = splitArray[1];

    let period = 'a.m';

    if (hours >= 12) {
      period = 'p.m';
      if (hours > 12) {
        hours -= 12;

        /* eslint-disable @typescript-eslint/typedef */
      }
    }

    // Add leading zeros if necessary
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');

    return `${formattedHours}:${formattedMinutes} ${period}`;
  }
}
